import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import BestRoundupLawyersWrapper from "@components/layouts/bestrounduplawyersWrapper";
import MainBanner from "@components/bestrounduplawyers/mainBanner";

const OffersList = dynamic(() => import("@components/shared/offersList"));
const Lawsuits = dynamic(
    () => import("@components/bestrounduplawyers/lawsuits"),
);
const Parkinson = dynamic(
    () => import("@components/bestrounduplawyers/parkinson"),
);
const SideEffects = dynamic(
    () => import("@components/bestrounduplawyers/sideEffects"),
);
const LawsuitsEligibility = dynamic(
    () => import("@components/bestrounduplawyers/lawsuitsEligibility"),
);

export default function BestRoundupLawyersHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <BestRoundupLawyersWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <Lawsuits />
            <SideEffects />
            <LawsuitsEligibility />
            <Parkinson />
        </BestRoundupLawyersWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
